/* eslint-disable no-console */
/* eslint-disable no-undef */
// utils/downloadFile.ts
import config from 'consts/config';
import { auth } from 'helpers';

export const downloadFile = async(
  url: string,
  fileName: string,
  data: any
): Promise<void> => {
  try {
    const accessToken = await auth.getAuthCredentials('access_token');

    const response = await fetch(`${config.baseUrl}/${url}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        'X-Channel': 'web_cms'
      }
    });

    // Check if response is OK
    if (!response.ok) {
      throw new Error(`Error fetching file: ${response.statusText}`);
    }

    // Convert response to Blob
    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);

    // Create a link element to trigger download
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    // Clean up by revoking the object URL and removing the link element
    window.URL.revokeObjectURL(downloadUrl);
    a.remove();
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};
