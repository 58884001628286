import React from 'react';
import { downloadFile } from 'helpers/downloadFile';
import { Endpoint } from 'helpers/api';
import Button from 'components/Button';
import Text from 'components/Text';
import { Colors } from 'consts';

type FileDownloadButtonProps = {
  btnText?: string;
  fileEndpoint: Endpoint;
  date?: string;
  filename: string;
}

const FileDownloadButton = ({
  btnText = 'download',
  fileEndpoint,
  date = new Date().toISOString(),
  filename,
}: FileDownloadButtonProps) => {
  const handleDownload = () => {
    const fileUrl = fileEndpoint.path;
    const fileName = `${filename}${date}.xlsx`;
    
    downloadFile(
      fileUrl,
      fileName, { date }
    );
  };

  return (
    <div>
      <Button
        borderRadius={ 10 }
        className='btn-header'
        onClick={ handleDownload }
      >
        <div className='flex row align-center'>

          <Text
            weight={ 700 }
            color={ Colors.white.default }
            ml={ 10 }
          >{ btnText }</Text>
        </div>
      </Button>
    </div>
  );
};

export default FileDownloadButton;
