import Styled from 'styled-components';

type ImageStyleProps = {
  width?: number | string;
  height?: number | string;
  type?: 'circle' | 'square';
  borderRadius?: number;
};

export const ImageContainer: any = Styled.div < ImageStyleProps > `
  display: flex;
  justify-content: center;

  .image-style {
    ${props => props.width && typeof props.width === 'number' ? `width: ${props.width}px;` : `width: ${props.width};`}
    ${props => props.height && typeof props.height === 'number' ? `height: ${props.height}px;` : `height: ${props.height};`}
    ${props => props.type === 'circle' ? 'border-radius: 100%;' : `border-radius: ${props.borderRadius || 0}px;`}
  }
`;
