import React, { useState } from 'react';
import { Layout } from 'antd';

import { Colors } from 'consts';
import {
  navigation,
  hooks,
  roleHelper,
  toastify
} from 'helpers';
import { selectors } from 'store/selectors';
import { RangeDateTime, Topic } from 'interfaces/common';

import { ContainerStyle, ContainerDrawerStyle } from './style';
import { topics } from './data';

import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import Content from '../Content';
import TopicMenu from '../TopicMenu';
import Text from '../Text';
import Button from '../Button';
import Icon from '../Icon';
import Input from 'components/Input';
import moment from 'moment';
import FileDownloadButton from 'components/FileDownloadButton';
import endpoints from 'consts/endpoints';
import { Endpoint } from 'helpers/api';

type HeaderContent = {
  textHeader?: string;
  textBtn?: string;
  btnDisabled?: boolean;
  iconNameBtn?: string;
  onClickBtn?: (date?: string) => Promise<void> | void;
  permissionBtn?: string | string[] | boolean;
  content?: React.ReactNode;
  withDatePicker?: boolean;
  placeholder?: string;
  key?: string;
  filename?: string;
  fileEndpoint?: (date: string) => Endpoint;
};

export type ContainerProps = {
  navbar?: any;
  navbarStyle?: string;
  backgroundColor?: string;
  withDrawer?: boolean;
  selectedMenu?: number;
  openSubmenu?: boolean;
  headerContent?: HeaderContent;
};

const Container: React.FC<ContainerProps> = ({
  children,
  navbar,
  backgroundColor,
  withDrawer,
  selectedMenu,
  openSubmenu,
  headerContent
}) => {
  const adminPermissions = hooks.useAppSelector(selectors.auth.adminPermissions);
  const adminRole = hooks.useAppSelector(selectors.auth.adminRole);

  const [selectedKey] = useState<number>(selectedMenu || 0);

  const changeSelectedKey = topic => {
    navigation.push(topic.route);
  };

  const renderNavbar = () => {
    if (navbar) {
      return <Navbar { ...navbar } />;
    }
  };

  const renderHeaderContent = () => {
    if (headerContent) {
      const {
        textHeader,
        iconNameBtn,
        onClickBtn,
        textBtn,
        btnDisabled,
        permissionBtn,
        content,
        withDatePicker = false,
        placeholder,
        filename = 'data.xlsx',
        fileEndpoint,
      } = headerContent;
      const isShowBtn = roleHelper.isPermissible(adminPermissions, permissionBtn);
      const [formDate, setFormDate] = useState('');
      const dateNow = moment(Date.now());

      const onChangeDate = (value: string) => {
        setFormDate(value);
      };

      return (
        <>
          <div className='justify-align-center gap4'>
            <Text
              size='xl'
              lineHeight={ 29 }
              weight={ 700 }
            >{ textHeader }</Text>
            {
              textBtn && isShowBtn ? (
                <div className='justify-align-center gap4'>
                  { fileEndpoint ? (
                    <>
                      { withDatePicker && (
                        <Input
                          type='date'
                          placeholder={ placeholder }
                          labelColor={ Colors.grey.isGrey }
                          value={ formDate }
                          name={ 'date' }
                          onChangeDate={ (value: string) => onChangeDate(value) }
                          defaultPickerValue={ dateNow }
                          backgroundColor={ Colors.white.default }
                        />
                      ) }
                      { fileEndpoint && (
                      <FileDownloadButton fileEndpoint={ fileEndpoint(formDate) } date={ formDate } btnText={ textBtn } filename={ filename } />
                      ) }
                    </>
                  ) : (
                    <Button
                      borderRadius={ 10 }
                      className='btn-header'
                      disabled={ btnDisabled }
                      onClick={ onClickBtn }
                    >
                      <div className='flex row align-center'>
                        {
                          iconNameBtn ? (
                            <Icon
                              size={ 14 }
                              iconName={ iconNameBtn }
                              fill={ Colors.white.default }
                            />
                          ) : null
                        }
                        <Text
                          weight={ 700 }
                          color={ Colors.white.default }
                          ml={ 10 }
                        >{ textBtn }</Text>
                      </div>
                    </Button>
                  ) }
                </div>
              ) : null
            }
          </div>

          { content }
        </>
      );
    }
  };

  const renderMenu = () => {
    const filteredTopics = topics.filter((topic: Topic) => {
      if (topic.submenus && topic.submenus?.length) {
        return topic.submenus.filter(subTopic => roleHelper.isPermissible(adminPermissions || [], subTopic.permissions || [])).length > 0;
      }

      return roleHelper.isPermissible(adminPermissions || [], topic.permissions || []);
    });
    const filteredTopicsConverted = adminRole?.toLowerCase()?.includes('customer service')
      ? [...filteredTopics].reverse()
      : filteredTopics;

    return (
      <TopicMenu
        topics={ filteredTopicsConverted }
        selectedKey={ selectedKey }
        changeSelectedKey={ changeSelectedKey }
        openSubmenu={ openSubmenu }
      />
    );
  };

  if (withDrawer) {
    return (
      <ContainerDrawerStyle>
        <Layout>
          <Sidebar menu={ renderMenu() } />
          <Layout>
            <Navbar
              { ...navbar }
              menu={ renderMenu() }
              navbarDrawer
            />
            <Layout.Content className='content'>
              { renderHeaderContent() }
              { children }
            </Layout.Content>
          </Layout>
        </Layout>
      </ContainerDrawerStyle>
    );
  }

  return (
    <ContainerStyle backgroundColor={ backgroundColor }>
      { renderNavbar() }
      <Content>
        { children }
      </Content>
    </ContainerStyle>
  );
};

Container.defaultProps = {
  backgroundColor: Colors.white.default,
  withDrawer: false,
  selectedMenu: 0,
  openSubmenu: false,
};

export default Container;
