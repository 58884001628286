import colors from 'consts/colors';
import Styled from 'styled-components';

export const TicketingStyle: any = Styled.div`
  display: flex;
  flex-direction: column;
`;

export const TabViewStyle: any = Styled.div`
	margin-top: 20px;
	width: 100%;
	padding: 0;
	
	ul {
		position: relative;
		display: flex;
		list-style: none;
		gap: 38px;
		height: 100%;

		::after {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			z-index: 10;
			bottom: 0;
			left: 0;
			height: 1px;
			background: #B0B0B0;
			/* border-bottom:1px solid #B0B0B0; */
		}
		
		li {
			padding-bottom: 10px;
			color: #B0B0B0;
			font-size: 16px;
			font-weight: 500;
			cursor: pointer;
			z-index: 20;
		}

		.active {
			color: ${colors.blue.isBlue};
			border-bottom:2px solid ${colors.blue.isBlue};
		}
	}
`;

export const SearchInputStyle: any = Styled.div`
	margin-top: 20px;
	margin-left: 20px;
`;

export const BtnActionCases: any = Styled.div`
	display: flex;
	align-items: center;
	column-gap: 10px;

	.container-cs {
		width: 140px;
	}
`;
