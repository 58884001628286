import React, {
  useState,
  useEffect,
  useCallback
} from 'react';
import { Empty } from 'antd';

import {
  Text,
  Icon,
  Box,
  Image,
  Spacer,
  Input,
  Divider,
  Switch,
  Upload,
  Select,
  SearchInput,
  Tooltip,
  Skeleton,
  Dashboard,
  ModalLoader
} from 'components';
import { MenuEntity } from 'components/Dropdown';
import { DataOption } from 'components/Input/SearchInput';
import { Colors } from 'consts';
import {
  hooks,
  misc,
  toastify,
  validation,
  screen,
  roleHelper
} from 'helpers';
import {
  ApiParams,
  CurrentPagination,
  FormPagination,
  ReducerList,
  UploadFileInfo
} from 'interfaces/common';
import {
  Admin,
  AdminDetail,
  ErrorFormAdmin,
  FormAdmin,
  FormChangeStatus
} from 'interfaces/admin';
import { Showroom, ShowroomApiParams } from 'interfaces/showroom';
import { Permission } from 'interfaces/role';
import { ActionModalForm, TooltipPlacement } from 'typings';
import { language } from 'language';
import { selectors } from 'store/selectors';
import * as actions from 'store/actions';

import { RoleManagementStyle } from './style';
import { inputPropsData } from './data';

interface UploadPhoto {
  loading: boolean;
  imageUrl: any;
}

type StatusStyle = {
  text: string;
  color: string;
};

type IconPasswordProps = {
  iconName: string;
  iconPosition: 'left' | 'right';
} | Record<string, never>;

type Selection = 'role_id' | 'showroom_id';
type VisibleSelect = {
  [key in Selection]: boolean;
};

const {
  label,
  placeholder,
  errorFormMessage,
  management,
  statusState
} = language;

const RoleManagement: React.FC = () => {
  const dispatch = hooks.useAppDispatch();
  const getAllRole = dispatch(actions.getAllRole);
  const getAllAdmin = dispatch(actions.getAllAdmin);
  const getAdminDetail = dispatch(actions.getAdminDetail);
  const addAdmin = dispatch(actions.addAdmin);
  const editAdmin = dispatch(actions.editAdmin);
  const changeStatusAdmin = dispatch(actions.changeStatusAdmin);
  const getAllShowroom = dispatch(actions.getAllShowroom);
  const getShowroomDetail = dispatch(actions.getShowroomDetail);

  const lazyLoad = hooks.useAppSelector(selectors.misc.lazyLoad);
  const adminPermissions = hooks.useAppSelector(selectors.auth.adminPermissions);
  const admins = hooks.useAppSelector(selectors.admin.admins);
  const roles = hooks.useAppSelector(selectors.role.roles);

  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [modalFormVisible, setModalFormVisible] = useState<boolean>(false);
  const [actionModalForm, setActionModalForm] = useState<ActionModalForm>(null);
  const [modalLoaderVisible, setModalLoaderVisible] = useState<boolean>(false);
  const [visibleSelect, setVisibleSelect] = useState<VisibleSelect>({
    role_id: false,
    showroom_id: false
  });
  const [formPagination, setFormPagination] = useState<FormPagination>({
    limit: 10,
    page: 1
  });
  const [uploadPhoto, setUploadPhoto] = useState<UploadPhoto>({
    loading: false,
    imageUrl: ''
  });
  const [form, setForm] = useState<FormAdmin>({
    name: '',
    username: '',
    email: '',
    phone_number: '',
    password: '',
    retype_password: '',
    role_id: 0,
    showroom_id: 0,
    image: null
  });
  const [errorForm, setErrorForm] = useState<ErrorFormAdmin>({
    name: '',
    username: '',
    email: '',
    phone_number: '',
    password: '',
    retype_password: '',
    role_id: '',
    showroom_id: ''
  });
  const [editedId, setEditedId] = useState<number>(-1);
  const [searchShowroom, setSearchShowroom] = useState<string>('');
  const [dataSearchShowroom, setDataSearchShowroom] = useState<DataOption[]>([]);
  const [selectedRole, setSelectedRole] = useState<string>(placeholder.role);
  const [openDropdownShowRoom, setOpenDropdownShowRoom] = useState<boolean>(false);

  const windowDimensions: hooks.Dimensions = hooks.useWindowDimensions();
  const debouncedSearchShowroom = hooks.useDebounce(searchShowroom, 1200);
  const loadingAdmins = misc.isLazyLoading(lazyLoad, 'allAdmin');

  const getDataAdmins = () => {
    const params: ApiParams = { ...formPagination };

    getAllAdmin(params);
  };

  useEffect(() => {
    getDataAdmins();
  }, [formPagination]);

  // Handle refreshing, ex: after add/edit/delete
  useEffect(() => {
    if (refreshing) {
      getDataAdmins();

      setRefreshing(false);
    }
  }, [refreshing]);

  // Handle search & select showroom in the form add scooter
  useEffect(() => {
    if (openDropdownShowRoom) {
      const formPaginationShowroom: FormPagination = {
        limit: 7,
        page: 1
      };
      const params: ShowroomApiParams = {
        ...formPaginationShowroom,
        search: debouncedSearchShowroom,
        sort: 'asc'
      };

      getAllShowroom(params, (updatedShowrooms: ReducerList<Showroom[]>) => {
        if (updatedShowrooms.data) {
          const dataShowroom = updatedShowrooms.data.map(showroom => ({
            value: showroom.id,
            text: showroom.name
          }));

          setDataSearchShowroom(dataShowroom);
        } else {
          setDataSearchShowroom([]);
        }
      });
    }
  }, [debouncedSearchShowroom, openDropdownShowRoom]);

  useEffect(() => {
    if (visibleSelect.role_id) {
      const params: ApiParams = {
        page: 1,
        limit: 7
      };

      getAllRole(params);
    }
  }, [visibleSelect.role_id]);

  useEffect(() => {
    
    if (editedId > -1) {
      getAdminDetail(editedId.toString(), handleCbGetAdminDetail);
    }
  }, [editedId]);

  const handleCbGetAdminDetail = (admin: AdminDetail) => {
    setForm(prevForm => ({
      ...prevForm,
      email: admin.email,
      role_id: admin.role_id,
      showroom_id: admin.showroom_id,
      image: misc.getImageUrl(admin.image, true)
    }));
    
    setUploadPhoto(prevPhoto => ({
      ...prevPhoto,
      imageUrl: misc.getImageUrl(admin.image, true)
    }));

    setOpenDropdownShowRoom(true);

    if (admin.role_id === 2) {
      getShowroomDetail(
        admin.showroom_id.toString(),
        'showroomDetail',
        (showroom: Showroom) => {
          setSearchShowroom(showroom.name);
          setModalLoaderVisible(false);
        },
        () => setModalLoaderVisible(false)
      );
    } else {
      setModalLoaderVisible(false);
    }
  };

  const onChangeStatusUser = (data: Admin) => {
    const formChangeStatus: FormChangeStatus = { is_active: Boolean(!data.is_active) };

    setModalLoaderVisible(true);

    changeStatusAdmin(
      data.id.toString(),
      formChangeStatus,
      handleCbChangeStatus
    );
  };

  const handleCbChangeStatus = (message: string) => {
    toastify.success(message);

    setModalLoaderVisible(false);

    setRefreshing(true);
  };

  const renderTextEllipsis = (
    text: string,
    size: string,
    weight: number,
    mb: number,
    color: string,
    ellipsis = true
  ) => {
    return (
      <Text
        size={ size }
        weight={ weight }
        mb={ mb }
        color={ color }
        className={ `p1 ${ ellipsis ? 'text-ellipsis' : '' }` }
      >{ text }</Text>
    );
  };

  const renderTooltip = (
    text: string,
    size = 's',
    weight = 500,
    mb = 0,
    color = Colors.black.isBlack,
    placement: TooltipPlacement = 'bottom'
  ) => {
    return (
      <Tooltip placement={ placement } title={ () => renderTextEllipsis(
        text,
        'xs',
        500,
        0,
        Colors.white.default,
        false
      ) }>
        { renderTextEllipsis(
          text,
          size,
          weight,
          mb,
          color
        ) }
      </Tooltip>
    );
  };

  const renderUserDataTopSection = (data: Admin, spacer: number) => {
    return (
      <div className='flex row align-center'>
        <Image
          src={ data.image }
          width={ 44 }
          height={ 44 }
          type='circle'
          external
          style={ { objectFit: 'cover' } }
        />

        <Spacer ml={ spacer } className='top-section'>
          { renderTooltip(
            data.name,
            'm',
            700,
            5,
            Colors.black.isBlack,
            'top'
          ) }
          { renderTooltip(
            (data.role_name || ''),
            'xs',
            500,
            0,
            Colors.grey.isGrey
          ) }
        </Spacer>
      </div>
    );
  };

  const renderIconEditProfile = () => {
    return (
      <>
        <Icon
          size={ 20 }
          iconName='edit'
          fill={ Colors.grey.isGrey }
        />
        <Spacer ml={ 10 }>
          <Text
            size='m'
            weight={ 700 }
            color={ Colors.grey.isGrey }
            className='p1 pointer'
          >{ management.editProfile }</Text>
        </Spacer>
      </>
    );
  };

  const renderTopSection = (data: Admin) => {
    if (windowDimensions.width >= screen.sizes.sm) {
      return renderUserDataTopSection(data, 15);
    } else {
      const isPermissibleEdit = roleHelper.isPermissible(adminPermissions, Permission.admin_update);

      return (
        <div className='justify-align-center mb4'>
          { renderUserDataTopSection(data, 15) }

          { isPermissibleEdit && (
            <div className='flex row align-center row-end pointer' onClick={ () => onClickOpenModal('edit', data) }>
              { renderIconEditProfile() }
            </div>
          ) }
        </div>
      );
    }
  };

  const renderTextData = (
    data: Admin,
    title: string,
    key: string,
    mb = 0,
  ) => {
    return (
      <>
        <Text
          lineHeight={ 15 }
          color={ Colors.grey.isGrey }
          mb={ mb }
        >{ title }</Text>
        { renderTooltip(
          data[key],
          's',
          500,
          0,
        ) }
      </>
    );
  };

  const renderStatusData = (data: Admin, status: StatusStyle) => {
    const screenWidth = windowDimensions.width;

    return (
      <>
        {
          screenWidth < screen.sizes.sm
            ? <Text lineHeight={ 15 } color={ Colors.grey.isGrey }>{ label.status }</Text>
            : null
        }
        <div className='flex row align-center'>
          <Switch
            size='sm'
            checked={ data.is_active }
            onChange={ () => onChangeStatusUser(data) }
          />
          <div className='text-status-container'>
            <Text
              size='m'
              weight={ 700 }
              lineHeight={ 19 }
              color={ status.color }
              align={ screenWidth < screen.sizes.sm ? 'right' : 'left' }
            >{ status.text }</Text>
          </div>
        </div>
      </>
    );
  };

  const renderContentBox = (data: Admin, status: StatusStyle) => {
    const screenWidth = windowDimensions.width;
    const isPermissibleEdit = roleHelper.isPermissible(adminPermissions, Permission.admin_update);

    return (
      <div className={ screenWidth >= screen.sizes.sm ? 'justify-align-center' : 'col' }>
        { renderTopSection(data) }
        <div className='content-section'>
          { renderTextData(
            data,
            label.username,
            'username',
            5,
          ) }
        </div>
        <div className='content-section'>
          { renderTextData(
            data,
            label.phone,
            'phone_number',
            5,
          ) }
        </div>
        <div className='content-section'>
          { renderStatusData(data, status) }
        </div>

        { isPermissibleEdit && (
          <div className='flex row align-center web-edit-profile'>
            <Divider type='vertical' className='divider-vertical' />
            <Spacer className='flex row align-center edit-table-container pointer' onClick={ () => onClickOpenModal('edit', data) }>
              { renderIconEditProfile() }
            </Spacer>
          </div>
        ) }
      </div>
    );
  };

  const renderBoxRoleManagement = () => {
    const adminList = loadingAdmins
      ? misc.createDummyData(5)
      : admins.data;

    if (!adminList.length) {
      return (
        <div className='col center-content' style={ { height: '40vh' } }>
          <Empty image={ Empty.PRESENTED_IMAGE_SIMPLE } />
        </div>
      );
    }

    return (
      <RoleManagementStyle>
        {
          adminList.map((data: Admin, index: number) => {
            const status: StatusStyle = {
              text: data.is_active ? statusState.active : statusState.inactive,
              color: data.is_active ? Colors.blue.isBlue : Colors.grey.isGrey
            };

            return (
              <Box
                key={ index }
                mt={ 20 }
                padding='20px 30px'
              >
                <Skeleton
                  loading={ loadingAdmins }
                  paragraph={ { rows: 2 } }
                  title={ false }
                >
                  { renderContentBox(data, status) }
                </Skeleton>
              </Box>
            );
          })
        }
      </RoleManagementStyle>
    );
  };

  const onCloseModalForm = () => {
    setModalFormVisible(false);

    setForm(prevForm => ({
      ...prevForm,
      name: '',
      email: '',
      username: '',
      phone_number: '',
      password: '',
      retype_password: '',
      role_id: 0,
      showroom_id: 0,
      image: ''
    }));

    setSelectedRole(placeholder.role);
    setSearchShowroom('');
    setEditedId(-1);

    setUploadPhoto(prevFormUpload => ({
      ...prevFormUpload,
      loading: false,
      imageUrl: ''
    }));

    setErrorForm({
      name: '',
      username: '',
      email: '',
      phone_number: '',
      password: '',
      retype_password: '',
      role_id: '',
      showroom_id: ''
    });

    setActionModalForm(null);
  };

  const renderUploadPhoto = () => {
    return (
      <div style={ { marginTop: -20 } }>
        <Upload
          uploadLoading={ uploadPhoto.loading }
          uploadPhotoUrl={ uploadPhoto.imageUrl }
          onChange={ onChangeUpload }
        />
      </div>
    );
  };

  const onChangeUpload = async(info: UploadFileInfo) => {
    const imageBase64 = await misc.getBase64(info.file);

    setUploadPhoto(prevPhoto => ({
      ...prevPhoto,
      imageUrl: imageBase64
    }));

    setForm(prevForm => ({
      ...prevForm,
      image: info.file
    }));
  };

  const onChangeFormText = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setForm(prevForm => ({
      ...prevForm,
      [e.target.name]: e.target.value
    }));

    setErrorForm(prevErrorForm => ({
      ...prevErrorForm,
      [e.target.name]: ''
    }));
  }, []);

  const onVisibleSelectChange = (visible: boolean, keyForm: string) => {
    setVisibleSelect(prevState => ({
      ...prevState,
      [keyForm]: visible
    }));
  };

  const onClickSelectItem = ({ key }: { key: string; }, keyForm: string) => {
    setVisibleSelect(prevState => ({
      ...prevState,
      [keyForm]: false
    }));

    const selectedRole = roles.data.find(role => role.name === key);

    setSelectedRole(key);

    setTimeout(() => {
      setForm(prevForm => ({
        ...prevForm,
        [keyForm]: selectedRole ? selectedRole.id : 1
      }));

      // If role selected is "Super Admin", set showroom_id 0 and search showroom empty string.
      if (selectedRole && selectedRole.id === 1) {
        setForm(prevForm => ({
          ...prevForm,
          showroom_id: 0
        }));

        setSearchShowroom('');
      } else if (selectedRole?.id === 2) {
        setOpenDropdownShowRoom(true);
      }

      setErrorForm(prevErrorForm => ({
        ...prevErrorForm,
        [keyForm]: ''
      }));
    }, 300);
  };

  const renderSelect = (
    label: string,
    placeholder: string,
    key: string
  ) => {
    const rolesName = roles && roles.data.length
      ? roles.data.map(role => role.name)
      : [];

    const loadingSelect = misc.isLazyLoading(lazyLoad, 'allRole');

    return (
      <Select
        type='form'
        visible={ visibleSelect[key] }
        onVisibleChange={ (visible: boolean) => onVisibleSelectChange(visible, key) }
        selectTitle={ selectedRole }
        label={ label }
        placeholder={ placeholder }
        onClickSelectItem={ (params: MenuEntity) => onClickSelectItem(params, key) }
        selectOptions={ rolesName }
        backgroundColor={ Colors.white.default }
        errorMessage={ errorForm[key] }
        loading={ loadingSelect }
      />
    );
  };

  const handleSearchShowroom = useCallback((query: string) => {
    setSearchShowroom(query);
  }, []);

  const handleInitSearchShowroom = value => {
    const selectedShowroom = dataSearchShowroom.find(showroom => showroom.value?.toString() === value?.toString());
    const textOnBoxInput = selectedShowroom ? selectedShowroom.text : '';

    setSearchShowroom(textOnBoxInput);
  };

  const handleChangeSearchShowroom = value => {
    handleInitSearchShowroom(value);

    setForm(prevForm => ({
      ...prevForm,
      showroom_id: + value
    }));

    setErrorForm(prevErrorForm => ({
      ...prevErrorForm,
      showroom_id: ''
    }));
  };

  const onDropdownShowRoomVisibleChange = (open: boolean) => {
    handleInitSearchShowroom(form.showroom_id);
    setOpenDropdownShowRoom(open);
  };

  const renderSearchShowroom = (
    label: string,
    placeholder: string,
    key: string
  ) => {
    if (form.role_id === 2) {
      const loadingGetShowroomBySearch = misc.isLazyLoading(lazyLoad, 'allShowroom');

      return (
        <SearchInput
          label={ label }
          labelColor={ Colors.grey.isGrey }
          value={ form.showroom_id && form.showroom_id > 0 ? form.showroom_id : '' }
          placeholder={ placeholder }
          data={ dataSearchShowroom }
          loading={ loadingGetShowroomBySearch }
          searchValue={ searchShowroom }
          onSearch={ handleSearchShowroom }
          onChange={ handleChangeSearchShowroom }
          backgroundColor={ Colors.white.default }
          onDropdownVisibleChange={ onDropdownShowRoomVisibleChange }
          disabled={ actionModalForm === 'detail' }
          errorMessage={ errorForm[key] }
        />
      );
    }

    return null;
  };

  const renderInput = (
    label: string,
    placeholder: string,
    key: string,
    type: string
  ) => {
    const iconPasswordProps: IconPasswordProps = type === 'password'
      ? {
        iconName: 'password',
        iconPosition: 'right'
      }
      : {};

    if (type === 'select') {
      return renderSelect(
        label,
        placeholder,
        key
      );
    } else if (type === 'search') {
      return renderSearchShowroom(
        label,
        placeholder,
        key
      );
    }

    return (
      <Input
        type={ type }
        label={ label }
        placeholder={ placeholder }
        labelColor={ Colors.grey.isGrey }
        value={ form[key] }
        errorMessage={ errorForm[key] }
        name={ key }
        onChange={ onChangeFormText }
        mb={ 20 }
        backgroundColor={ Colors.white.default }
        disabled={ actionModalForm === 'detail' }
        { ...iconPasswordProps }
      />
    );
  };

  const handleFormValidation = () => {
    const validationUsername = !validation.email(form.username);
    const validationEmail = validation.email(form.email);
    const validationPhone = validation.phoneNumber(misc.phoneNumberFormat(form.phone_number));
    const validationPassMatch = actionModalForm === 'add'
      ? validation.passwordMatch((form.password || ''), (form.retype_password || ''))
      : true;
    const validationPassLength = actionModalForm === 'add'
      ? validation.passwordLength((form.password || ''))
      : true;
    const emptyForm = misc.getMultipleKeyByArrOfValue(form, ['', 0]).filter(keyForm => {
      if (form.role_id !== 2) {
        if (actionModalForm === 'edit') {
          return keyForm !== 'showroom_id'
            && keyForm !== 'password'
            && keyForm !== 'retype_password'
            && keyForm !== 'image';
        } else {
          return keyForm !== 'showroom_id' && keyForm !== 'image';
        }
      } else {
        if (actionModalForm === 'edit') {
          return keyForm !== 'password'
            && keyForm !== 'retype_password'
            && keyForm !== 'image';
        }
      }

      return keyForm !== 'image';
    });

    if (emptyForm.length ||
      !validationUsername ||
      !validationEmail ||
      !validationPhone ||
      !validationPassMatch ||
      !validationPassLength
    ) {
      const errMsgPassword = !validationPassLength
        ? errorFormMessage.password
        : !validationPassMatch
          ? errorFormMessage.passwordMatch
          : '';

      setErrorForm(prevErrorForm => ({
        ...prevErrorForm,
        username: !validationUsername ? errorFormMessage.username : '',
        email: !validationEmail ? errorFormMessage.email : '',
        phone_number: !validationPhone ? errorFormMessage.phone : '',
        password: errMsgPassword,
        retype_password: errMsgPassword,
      }));

      emptyForm.forEach(key => {
        inputPropsData.forEach(input => {
          if (key === input.key) {
            setErrorForm(prevErrorForm => ({
              ...prevErrorForm,
              [key]: errorFormMessage.form(input.label.toLowerCase())
            }));
          }
        });
      });

      return false;
    }

    return true;
  };

  const onClickButtonModalForm = async() => {
    if (handleFormValidation()) {
      const updatedForm = {
        ...form,
        phone_number: misc.phoneNumberFormat(form.phone_number)
      };
      let formPayload = updatedForm;

      // If not showroom admin, remove showroom_id
      if (updatedForm.role_id !== 2) {
        formPayload = await misc.removeProperties(updatedForm, 'showroom_id');
      }

      if (actionModalForm === 'add') {
        addAdmin(formPayload, handleCbForm);
      } else {
        editAdmin(
          editedId.toString(),
          formPayload,
          handleCbForm
        );
      }
    }
  };

  const handleCbForm = async(message: string) => {
    await onCloseModalForm();

    await toastify.success(message);

    setRefreshing(true);
  };

  const renderContentModalForm = () => {
    const filteredInputProps = editedId > -1
      ? inputPropsData.filter(input => input.type !== 'password')
      : inputPropsData;

    return (
      <>
        { renderUploadPhoto() }
        {
          filteredInputProps.map((input, index) => {
            return (
              <div key={ index }>
                { renderInput(
                  input.label,
                  input.placeholder,
                  input.key,
                  input.type
                ) }
              </div>
            );
          })
        }
      </>
    );
  };

  const onClickOpenModal = (actionModal: ActionModalForm, record?: Admin) => {
    setActionModalForm(actionModal);

    if (record) {
      if (actionModal === 'edit') {
        setModalLoaderVisible(true);

        setEditedId(record.id);

        setForm(prevForm => ({
          ...prevForm,
          name: record.name,
          username: record.username,
          phone_number: record.phone_number,
        }));
        setSelectedRole((record.role_name || ''));
      }
    }

    if (actionModal === 'add') {
      setModalFormVisible(true);
    }
  };

  const getCurrentPagination = (currentPagination: CurrentPagination<Admin[]>) => {
    setFormPagination(prevPagination => ({
      ...prevPagination,
      page: currentPagination.currentPage,
      limit: currentPagination.elementsPerPage
    }));
  };

  const renderModalLoader = () => {
    return (
      <ModalLoader
        visible={ modalLoaderVisible }
        onCloseModal={ () => setModalLoaderVisible(false) }
        afterClose={ () => {
          if (actionModalForm === 'edit') {
            setModalFormVisible(true);
          }
        } }
      />
    );
  };

  return (
    <Dashboard
      container={ {
        selectedMenu: 9,
        headerContent: {
          textHeader: management.header.title,
          textBtn: management.header.textBtn,
          iconNameBtn: 'add',
          onClickBtn: () => onClickOpenModal('add'),
          permissionBtn: Permission.admin_create
        }
      } }
      data={ {
        currentData: admins.data,
        loading: loadingAdmins,
        fieldName: 'admins',
        getCurrentPagination
      } }
      modalForm={ {
        title: 'user',
        visible: modalFormVisible,
        actionModal: actionModalForm,
        onCloseModal: onCloseModalForm,
        footer: {
          fieldName: 'admin',
          onClickButtonSubmit: onClickButtonModalForm
        },
        contentModal: renderContentModalForm()
      } }
      modalLoader={ renderModalLoader() }
      content={ renderBoxRoleManagement() }
    />
  );
};

export default RoleManagement;
