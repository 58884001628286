import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Container } from 'components';
import {
  navigation,
  hooks,
  misc,
  screen
} from 'helpers';

import {
  BoxBattery,
  BoxGeneralInfo,
  BoxVinLog,
  BoxDischarge,
  BoxTemp
} from './components';

import { BatteryPackDetailStyle } from './style';
import { Permission } from 'interfaces/role';
import * as actions from 'store/actions';
import endpoints from 'consts/endpoints';

export interface RouteParams {
  batteryId: string;
}

type Filter =
  'batteryConsumption' |
  'discharge_level' |
  'discharge_time' |
  'temp_level' |
  'temp_time';

type ActiveFilter = {
  [key in Filter]: string;
};

type VisibleFilter = {
  [key in Filter]: boolean;
};

const BatteryPackDetail: React.FC = () => {
  const params = useParams<RouteParams>();

  const [visibleFilter, setVisibleFilter] = useState<VisibleFilter>({
    batteryConsumption: false,
    temp_level: false,
    temp_time: false,
    discharge_level: false,
    discharge_time: false,
  });
  const [activeFilter, setActiveFilter] = useState<ActiveFilter>({
    batteryConsumption: 'Daily',
    temp_level: 'Average',
    temp_time: 'Last 7 Days',
    discharge_level: 'Average',
    discharge_time: 'Last 7 Days',
  });

  const windowDimensions: hooks.Dimensions = hooks.useWindowDimensions();

  const onClickFilterMenu = ({ key }: { key: string; }) => {
    const filterOpen = misc.getKeyByValue(visibleFilter, true);

    setVisibleFilter(prevFilter => ({
      ...prevFilter,
      [`${ filterOpen }`]: false
    }));
    setActiveFilter(prevActive => ({
      ...prevActive,
      [`${ filterOpen }`]: key
    }));
  };

  const onVisibleChange = (visible: boolean, key: string) => {
    setVisibleFilter(prevFilter => ({
      ...prevFilter,
      [key]: visible
    }));
  };

  const filterMenuProps = {
    visibleFilter,
    activeFilter,
    onVisibleChange,
    onClickFilterMenu
  };

  const renderBoxTemp = (widthBox: number) => {
    const filterMenu = {
      ...filterMenuProps,
      // keys: ['temp_level', 'temp_time'],
      // selectOptions: [selectStatOptions, ['Last 7 days', 'Last 24 Hours']]
      keys: ['temp_time'],
      selectOptions: [['Last 7 days', 'Last 24 Hours']]
    };

    return (
      <BoxTemp
        widthBox={ widthBox }
        filterMenu={ filterMenu }
        routeId={ params?.batteryId }
      />
    );
  };

  const renderBoxDischarge = (widthBox: number) => {
    const filterMenu = {
      ...filterMenuProps,
      // keys: ['discharge_level', 'discharge_time'],
      // selectOptions: [selectStatOptions, ['Last 7 Days', 'Last 24 Hours']]
      keys: ['discharge_time'],
      selectOptions: [['Last 7 Days', 'Last 24 Hours']]
    };

    return (
      <BoxDischarge
        widthBox={ widthBox }
        filterMenu={ filterMenu }
        routeId={ params?.batteryId }
      />
    );
  };

  const renderContent = () => {
    const { width: screenWidth } = windowDimensions;
    let widthBox1 = (screenWidth - 240) / 4;
    let widthBox2 = ((screenWidth - 240) / 2) - 20;

    if (screenWidth <= screen.sizes.sx && screenWidth > screen.sizes.sm) {
      widthBox1 = screenWidth / 4;
      widthBox2 = ((screenWidth) / 2) - 40;
    } else if (screenWidth <= screen.sizes.sm) {
      widthBox1 = screenWidth - 60;
      widthBox2 = screenWidth - 60;
    }

    return (
      <div>
        <div className='box-section-container'>
          <BoxGeneralInfo widthBox={ widthBox1 } />
          <BoxVinLog
            widthBox={ widthBox1 }
            routeId={ params?.batteryId }
          />
          <BoxBattery
            widthBox={ widthBox2 }
            filterMenu={ filterMenuProps }
            routeId={ params?.batteryId }
          />
        </div>
        <div className='box-section-container'>
          { renderBoxDischarge(widthBox2) }
          { renderBoxTemp(widthBox2) }
        </div>
      </div>
    );
  };

  const dispatch = hooks.useAppDispatch();
  const getBatteryLogCSV = dispatch(actions.getBatteryLogCSV);

  const downloadData = async(date?: string) => {
    getBatteryLogCSV(params?.batteryId, date || new Date().toISOString());
  };

  return (
    <Container
      navbar={ {
        backButton: 'circle',
        onClickBack: () => navigation.goBack()
      } }
      selectedMenu={ 6 }
      headerContent={ {
        textHeader: `Battery Pack ${ params?.batteryId }`,
        textBtn: 'Download Data',
        filename: `battery-log_${ params?.batteryId }_`,
        withDatePicker: true,
        onClickBtn: (date?: string) => downloadData(date),
        fileEndpoint: (date: string) => endpoints.getBatteryLogCSV(params?.batteryId, date),
        permissionBtn: Permission.battery_create // Permission.battery_download_data
      } }
      withDrawer
    >
      <BatteryPackDetailStyle>
        { renderContent() }
      </BatteryPackDetailStyle>
    </Container>
  );
};

export default BatteryPackDetail;
