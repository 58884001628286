import React, { useEffect } from 'react';

import { Colors } from 'consts';

import { SelectStyle } from './style';

import Dropdown, { MenuEntity } from '../Dropdown';
import Icon from '../Icon';
import Text from '../Text';

type SelectProps = {
  selectOptions: string[];
  label?: string;
  selectTitle: string;
  placeholder?: string;
  visible: boolean;
  onVisibleChange: (visible: boolean, key: string) => void;
  onClickSelectItem?: (params: MenuEntity) => void;
  type?: 'form' | 'filter';
  keySelect?: string;
  backgroundColor?: string;
  errorMessage?: string;
  isDisabled?: boolean;
  loading?: boolean;
  totalItems?: number;
  defaultWidth?: string;
  wrapperStyle?: React.CSSProperties;
  withMb?: boolean;
};

const Select: React.FC<SelectProps> = ({
  label,
  selectTitle,
  placeholder,
  selectOptions,
  visible,
  onVisibleChange,
  onClickSelectItem,
  type,
  keySelect,
  backgroundColor,
  errorMessage,
  isDisabled,
  loading,
  totalItems,
  defaultWidth,
  wrapperStyle,
  withMb = true
}) => {
  useEffect(() => {
    const closeDropdown = () => onVisibleChange(false, (keySelect || ''));

    window.addEventListener(
      'scroll',
      closeDropdown,
      true
    );

    return () => window.removeEventListener(
      'scroll',
      closeDropdown,
      true
    );
  }, []);

  const renderSelectItem = (selectItem: string) => {
    return <Text lineHeight={ 24 }>{ selectItem }</Text>;
  };

  const overlaySelectData = selectOptions.map(selectItem => {
    return {
      key: selectItem,
      item: () => renderSelectItem(selectItem)
    };
  });

  const renderLabel = () => {
    if (label) {
      return (
        <Text
          lineHeight={ 17 }
          weight={ 500 }
          text={ label }
          color={ visible ? Colors.blue.isBlue : Colors.grey.isGrey }
          mb={ 10 }
        />
      );
    }

    return null;
  };

  const renderErrorText = () => {
    if (errorMessage) {
      return (
        <Text
          size='xxs'
          mt={ 5 }
          color={ Colors.red.isTextRed }
        >{ errorMessage }</Text>
      );
    }

    return null;
  };

  const renderSelect = () => {
    const textColor = (selectTitle !== placeholder && type === 'form') || type === 'filter'
      ? Colors.black.isBlack
      : Colors.grey.isGrey;

    return (
      <Dropdown
        visible={ visible }
        onVisibleChange={ visible => onVisibleChange(visible, (keySelect || '')) }
        overlayData={ overlaySelectData }
        placement='bottom'
        trigger={ ['click'] }
        borderRadius={ 10 }
        onClickMenu={ onClickSelectItem }
        disabled={ isDisabled }
        loading={ loading }
        selectedValue={ selectTitle }
      >
        <SelectStyle
          openSelect={ visible }
          backgroundColor={ backgroundColor }
          isDisabled={ isDisabled }
          totalItems={ totalItems }
          defaultWidth={ defaultWidth }
        >
          <div className={ `${ type === 'form' ? 'select-form-container' : 'select-filter-container' } justify-align-center text-ellipsis` }>
            <Text color={ textColor } className={ `p1 ${ type === 'filter' ? 'select-text-item text-ellipsis' : '' }` }>{ selectTitle }</Text>
            <Icon
              iconName='arrowDown'
              size={ 13 }
              fill={ Colors.blue.isBlue }
              className='ic-arrow'
            />
          </div>
        </SelectStyle>
      </Dropdown>
    );
  };

  return (
    <div className={ withMb ? 'mb4' : '' } style={ wrapperStyle }>
      { renderLabel() }

      { renderSelect() }

      { renderErrorText() }
    </div>
  );
};

Select.defaultProps = {
  type: 'filter',
  defaultWidth: '200px'
};

export default Select;
