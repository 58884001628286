import React, {
	useCallback,
	useEffect,
	useState
} from 'react';
import { Empty, Menu } from 'antd';

import {
	Dropdown,
	Icon,
	Input,
	Loader,
	RadioButton,
	Button,
	Divider
} from 'components';
import { renderText } from 'components/DataTable';
import {
	hooks,
	misc,
	toastify
} from 'helpers';
import { DataTicketing } from 'interfaces/ticketing';
import { SelectOptionValue } from 'interfaces/common';
import { Admin } from 'interfaces/admin';
import { Colors } from 'consts';
import { language } from 'language';
import { selectors } from 'store/selectors';
import * as actions from 'store/actions';

import {
	MenuAssignCSStyle,
	MenuDropdownStyle,
	SearchInputStyle
} from './style';

const EmptyState = ({
	warnKey, // eslint-disable-line @typescript-eslint/no-unused-vars
	eventKey, // eslint-disable-line @typescript-eslint/no-unused-vars
	...rest
}) => {
	// `rest` props are all standard DOM attributes.
	return <Empty { ...rest } />;
};

const convertDataAdmins = (adminList: Admin[]): SelectOptionValue[] => {
	return adminList?.map((admin: Admin) => ({
		key: `${ admin.id }`,
		value: `${ admin.id }`,
		label: admin.name
	}));
};

type MenuAssignCSProps = {
	record: DataTicketing;
	mobile?: boolean;
	dropdownCellVisible?: boolean;
	setDropdownCellVisible?: React.Dispatch<React.SetStateAction<boolean>>;
	onSuccess?: () => void;
};

const ticketingLang = language.ticketing;

const MenuAssignCS: React.FC<MenuAssignCSProps> = ({
	record,
	mobile,
	setDropdownCellVisible,
	onSuccess
}) => {
	const dispatch = hooks.useAppDispatch();

	const getAllAdmin = dispatch(actions.getAllAdmin);
	const assignCS = dispatch(actions.assignCS);

	const lazyLoad = hooks.useAppSelector(selectors.misc.lazyLoad);

	const [valueInSearch, setValueInSearch] = useState<string>('');
	const [radioCS, setRadioCS] = useState<string>(`${ record?.admin_id || '' }`);
	const [menuMobileVisible, setMenuMobileVisible] = useState<boolean>(false);
	const [optionsAdmin, setOptionsAdmin] = useState<SelectOptionValue[]>([]);

	const debounceSearch = hooks.useDebounce(valueInSearch, 800);

	const onClickAssignCS = () => {
		assignCS(
			record?.id?.toString(),
			{ cs_id: radioCS },
			() => {
				if (setDropdownCellVisible) setDropdownCellVisible(false); // dropdown cell table web display
				setMenuMobileVisible(false); // dropdown mobile display

				toastify.success(`Successfully assign cs to ${ record?.case_code || '' }`);

				if (onSuccess) onSuccess();
			}
		);
	};

	const getDataAdminOptions = () => {
		getAllAdmin({
			page: 1,
			limit: 7,
			sort: 'asc',
			search: debounceSearch,
			role_name: 'Customer Service'
		}, (admins: Admin[]) => {
			setOptionsAdmin(convertDataAdmins(admins));
		});
	};

	useEffect(() => {
		if (mobile) {
			if (menuMobileVisible) {
				getDataAdminOptions();
			}
		} else {
			getDataAdminOptions();
		}
	}, [
		debounceSearch,
		menuMobileVisible,
		mobile
	]);

	const onChangeRadioCS = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setRadioCS(`${ e.target.value }`);
	}, []);

	const renderSearchCS = () => {
		return (
			<SearchInputStyle>
				<div>
					<Input
						placeholder='Search'
						value={ valueInSearch }
						name='search'
						onChange={ (e: React.ChangeEvent<HTMLInputElement>) => setValueInSearch(e.target.value) }
						mb={ 20 }
						borderRadius={ 8 }
						width='100%'
						weight={ 400 }
						iconName='search'
						iconClassName='ic-search'
					/>
				</div>
			</SearchInputStyle>
		);
	};

	const renderButtonAssignCS = () => {
		const loadingAssignCS = misc.isLazyLoading(lazyLoad, 'assignCS');

		return (
			<Button
				size='m'
				fontWeight={ 700 }
				height={ 43 }
				borderRadius={ 10 }
				mt={ 5 }
				disabled={ !radioCS || loadingAssignCS }
				isLoading={ loadingAssignCS }
				onClick={ onClickAssignCS }
				text={ ticketingLang?.assignCS }
			/>
		);
	};

	const renderDataCS = () => {
		if (optionsAdmin && optionsAdmin?.length) {
			return (
				<div className='wrapper-cs-list'>
					{ optionsAdmin?.map((option, optIndex) => {
						return (
							<div key={ `${ record?.case_code }-${ optIndex }` } className='flex col'>
								<RadioButton
									label={ option?.label }
									name={ `cs-${ option?.value }` }
									value={ option?.value }
									checked={ radioCS }
									onChange={ onChangeRadioCS }
									inverse
								/>

								<Divider marginVertical={ 10 } />
							</div>
						);
					}) }

					{ renderButtonAssignCS() }
				</div>
			);
		}

		return (
			<EmptyState
				warnKey={ record?.case_code }
				eventKey={ record?.case_code }
				image={ Empty.PRESENTED_IMAGE_SIMPLE }
			/>
		);
	};

	const renderMenuDropdown = () => {
		const loadingGetListCS = misc.isLazyLoading(lazyLoad, 'allAdmin');

		return (
			<MenuDropdownStyle>
				<Menu className='menu-assign-cs'>
					{ renderSearchCS() }
					{ loadingGetListCS
						? <Loader className='col center-content' style={ { height: 100 } } />
						: renderDataCS()
					}
				</Menu>
			</MenuDropdownStyle>
		);
	};

	const renderMenuAssignCS = () => {
		if (mobile) {
			return (
				<Dropdown
					borderRadius={ 10 }
					visible={ menuMobileVisible }
					onVisibleChange={ visible => setMenuMobileVisible(visible) }
					overlay={ renderMenuDropdown() }
					placement='bottomRight'
					trigger={ ['click'] }
				>
					<div className='flex align-center'>
						{ renderText(record?.admin_name || ticketingLang?.assignCS) }

						<Icon
							iconName='arrowDown'
							size={ 13 }
							fill={ Colors.blue.isBlue }
							className='ic-arrow'
						/>
					</div>
				</Dropdown>
			);
		}

		return renderMenuDropdown();
	};

	return (
		<MenuAssignCSStyle key={ record?.case_code } isOpen={ menuMobileVisible }>
			{ renderMenuAssignCS() }
		</MenuAssignCSStyle>
	);
};

export default MenuAssignCS;
