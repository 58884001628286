import React, {
  useState,
  useEffect,
  useCallback
} from 'react';
import { ColumnsType } from 'antd/es/table';

import {
  Input,
  Select,
  Dashboard,
  TopLoaderBar
} from 'components';
import { renderText, renderButtonAction } from 'components/DataTable';
import { convertColorStatus } from 'components/DataTable/helpers';
import { MenuEntity } from 'components/Dropdown';
import { Colors } from 'consts';
import {
  hooks,
  misc,
  navigation,
  toastify
} from 'helpers';
import {
  CurrentPagination,
  FormPagination,
  SorterInfo
} from 'interfaces/common';
import {
  BatteryApiParams,
  BatteryPack,
  FormBatteryPack
} from 'interfaces/battery';
import { Permission } from 'interfaces/role';
import { ActionModalForm, DetailNavProps } from 'typings';
import { language } from 'language';
import { selectors } from 'store/selectors';
import * as actions from 'store/actions';

import {
  inputPropsData,
  dropdownsFilter,
  statusOptions,
  locationOptions
} from './data';

type Filter = 'status' | 'location';
type ActiveFilter = {
  [key in Filter]: string;
};

type Selection = 'status';
type VisibleSelect = {
  [key in Selection]: boolean;
};

const { errorFormMessage } = language;

const BatteryPacks: React.FC = () => {
  const dispatch = hooks.useAppDispatch();
  const getAllBatteryPack = dispatch(actions.getAllBatteryPack);
  const getBatteryPackDetail = dispatch(actions.getBatteryPackDetail);
  const addBatteryPack = dispatch(actions.addBatteryPack);
  const editBatteryPack = dispatch(actions.editBatteryPack);

  const lazyLoad = hooks.useAppSelector(selectors.misc.lazyLoad);
  const batteryPacks = hooks.useAppSelector(selectors.battery.batteryPacks);

  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [activeFilter, setActiveFilter] = useState<ActiveFilter>({
    status: 'All Status',
    location: 'All Location'
  });
  const [sortOrder, setSortOrder] = useState<SorterInfo>({
    sort: null,
    order: ''
  });
  const [visibleSelect, setVisibleSelect] = useState<VisibleSelect>({ status: false });
  const [editedId, setEditedId] = useState<number>(-1);
  const [actionModalForm, setActionModalForm] = useState<ActionModalForm>(null);
  const [modalFormVisible, setModalFormVisible] = useState<boolean>(false);
  const [form, setForm] = useState<FormBatteryPack>({
    id: '',
    soc: '',
    capacity_remaining: '',
    status: 'Choose battery status',
    location: 'Choose battery location',
    soh: ''
  });
  const [errorForm, setErrorForm] = useState<FormBatteryPack>({
    id: '',
    soc: '',
    capacity_remaining: '',
    status: '',
    location: '',
    soh: ''
  });
  const [formPagination, setFormPagination] = useState<FormPagination>({
    limit: 10,
    page: 1
  });
  const [search, setSearch] = useState<string>('');
  const [goToBatteryDetail, setGoToBatteryDetail] = useState<DetailNavProps>({
    progress: -1,
    id: ''
  });

  const debouncedSearch = hooks.useDebounce(search, 1200);
  const loadingBatteryPacks = misc.isLazyLoading(lazyLoad, 'allBatteryPack');

  const buttonsDataTable = [
    {
      iconName: 'search',
      color: 'blue',
      onClick: (record: BatteryPack) => onClickRow(record),
      permission: Permission.battery_detail
      // onClick: (record: BatteryPack) => onClickOpenModal('detail', record), // note: jika fitur modal detail + edit diadakan seperti di halaman showroom, uncomment line ini, dan comment line onClick diatas
    }
  ];

  const columns: ColumnsType<BatteryPack> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      render: text => renderText(text)
    },
    {
      title: 'SoC',
      dataIndex: 'soc',
      key: 'soc',
      sorter: true,
      render: text => renderText(text),
    },
    // {
    //   title: 'Capacity',
    //   dataIndex: 'capacity',
    //   key: 'capacity',
    //   render: text => renderText(text),
    // },
    {
      title: 'Vin',
      dataIndex: 'vin',
      key: 'vin',
      render: text => renderText(text),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: text => renderText(text, convertColorStatus(text)),
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      render: text => renderText(text),
    },
    {
      title: 'SoH',
      dataIndex: 'soh',
      key: 'soh',
      sorter: true,
      render: text => renderText(text),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      width: 120,
      render: (_: any, record: BatteryPack) => renderButtonAction(buttonsDataTable, record)
    },
  ];

  const getDataBatteryPacks = () => {
    const params: BatteryApiParams = {
      ...formPagination,
      search: debouncedSearch,
      sort: (sortOrder.sort || 'asc'),
      order: sortOrder.order,
      status: misc.changeSelectFilterValue(activeFilter.status, 'status')?.toLowerCase(),
      location: misc.changeSelectFilterValue(activeFilter.location, 'location')?.toLowerCase()
    };

    getAllBatteryPack(params);
  };

  useEffect(() => {
    getDataBatteryPacks();
  }, [
    formPagination.page,
    formPagination.limit,
    activeFilter.location,
    activeFilter.status,
    sortOrder.order,
    sortOrder.sort,
    debouncedSearch
  ]);

  // Handle refreshing, ex: after add/edit/delete
  useEffect(() => {
    if (refreshing) {
      getDataBatteryPacks();

      setRefreshing(false);
    }
  }, [refreshing]);

  const setInitialPage = () => {
    setFormPagination(prevFormPagination => ({
      ...prevFormPagination,
      page: 1
    }));
  };

  const onChangeFormText = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setForm(prevForm => ({
      ...prevForm,
      [e.target.name]: e.target.value
    }));

    setErrorForm(prevErrorForm => ({
      ...prevErrorForm,
      [e.target.name]: ''
    }));
  }, []);

  const onVisibleSelectChange = (visible: boolean, keyForm: string) => {
    setVisibleSelect(prevState => ({
      ...prevState,
      [keyForm]: visible
    }));
  };

  const onClickSelectItem = ({ key }: { key: string; }, keyForm: string) => {
    setVisibleSelect(prevState => ({
      ...prevState,
      [keyForm]: false
    }));

    setTimeout(() => {
      setForm(prevForm => ({
        ...prevForm,
        [keyForm]: key
      }));

      setErrorForm(prevErrorForm => ({
        ...prevErrorForm,
        [keyForm]: ''
      }));
    }, 300);
  };

  const renderSelect = (
    label: string,
    placeholder: string,
    key: string
  ) => {
    const selectOptions = key === 'status'
      ? statusOptions
      : locationOptions;

    return (
      <Select
        type='form'
        visible={ visibleSelect[key] }
        onVisibleChange={ (visible: boolean) => onVisibleSelectChange(visible, key) }
        selectTitle={ form[key] }
        label={ label }
        placeholder={ placeholder }
        onClickSelectItem={ (params: MenuEntity) => onClickSelectItem(params, key) }
        selectOptions={ selectOptions }
        backgroundColor={ Colors.white.default }
        isDisabled={ actionModalForm === 'detail' }
        errorMessage={ errorForm[key] }
      />
    );
  };

  const renderInput = (
    label: string,
    placeholder: string,
    key: string,
    type: string
  ) => {
    if (type === 'select') {
      return renderSelect(
        label,
        placeholder,
        key
      );
    }

    const inputDisabled = actionModalForm === 'detail' ||
      (key !== 'status' &&
        key !== 'location' &&
        actionModalForm !== 'add');

    return (
      <Input
        type={ inputDisabled ? 'text' : type }
        label={ label }
        placeholder={ placeholder }
        labelColor={ Colors.grey.isGrey }
        value={ form[key] }
        errorMessage={ errorForm[key] }
        name={ key }
        onChange={ onChangeFormText }
        mb={ 20 }
        backgroundColor={ Colors.white.default }
        disabled={ inputDisabled }
      />
    );
  };

  const onClickOpenModal = (actionModal: ActionModalForm, record?: BatteryPack) => {
    setActionModalForm(actionModal);

    if (record) {
      setEditedId(+ record.id);

      setForm(prevForm => ({
        ...prevForm,
        id: record.id,
        soc: record.soc,
        capacity: record.capacity_remaining,
        status: record.status,
        soh: record.soh
      }));
    }

    setModalFormVisible(true);
  };

  const onCloseModalForm = () => {
    setModalFormVisible(false);
    setActionModalForm(null);

    setEditedId(-1);

    const emptyState = {
      id: '',
      soc: '',
      capacity: '',
      status: '',
      location: '',
      soh: ''
    };

    setForm({
      ...emptyState,
      status: 'Choose battery status',
      location: 'Choose battery location'
    });

    setErrorForm(emptyState);
  };

  const handleFormValidation = () => {

    const columnValidated = {
      id: form.id,
      status: form.status,
    };

    const emptyForm = misc.getMultipleKeyByArrOfValue(columnValidated, ['']);

    if (emptyForm.length) {
      emptyForm.forEach(key => {
        inputPropsData.forEach(input => {
          if (key === input.key) {
            setErrorForm(prevErrorForm => ({
              ...prevErrorForm,
              [key]: errorFormMessage.form(input.label.toLowerCase())
            }));
          }
        });
      });

      return false;
    }

    return true;
  };

  const onClickButtonModalForm = () => {
    if (handleFormValidation()) {
      if (actionModalForm === 'add') {
        const payload = { id: Number(form.id) };

        addBatteryPack(payload, handleCbFormBattery);
      } else if (actionModalForm === 'edit') {
        editBatteryPack(
          editedId.toString(),
          form,
          handleCbFormBattery
        );
      }
    }
  };

  const handleCbFormBattery = (data: any) => {
    onCloseModalForm();
    toastify.success(data);

    setRefreshing(true);
  };

  const renderContentModalForm = () => {
    return inputPropsData.map((input, index) => {
      return (
        <div key={ index }>
          { renderInput(
            input.label,
            input.placeholder,
            input.key,
            input.type
          ) }
        </div>
      );
    });
  };

  const onChangeSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);

    setInitialPage();
  }, []);

  const onClickMenuItem = ({ key: keyItem }, keyFilter) => {
    setActiveFilter(prevState => ({
      ...prevState,
      [keyFilter]: keyItem
    }));

    setInitialPage();
  };

  const onChangeTable = (sorterInfo: SorterInfo) => {
    setSortOrder(sorterInfo);

    setInitialPage();
  };

  const getCurrentPagination = (currentPagination: CurrentPagination<BatteryPack[]>) => {
    setFormPagination(prevPagination => ({
      ...prevPagination,
      page: currentPagination.currentPage,
      limit: currentPagination.elementsPerPage
    }));
  };

  const onClickRow = (record: BatteryPack) => {
    const batteryId = record.id.toString();

    setGoToBatteryDetail({
      progress: 0,
      id: batteryId
    });

    getBatteryPackDetail(batteryId, () => {
      setGoToBatteryDetail(prevState => ({
        ...prevState,
        progress: 100
      }));
    });
  };

  const renderTopLoadingBar = () => {
    return (
      <TopLoaderBar
        progress={ goToBatteryDetail.progress }
        onLoaderFinished={ () => navigation.push(`/dashboard/battery-packs/${ goToBatteryDetail.id }`) }
      />
    );
  };

  return (
    <Dashboard
      container={ {
        selectedMenu: 6,
        headerContent: {
          textHeader: 'Battery Packs',
          textBtn: 'Add Battery',
          iconNameBtn: 'add',
          onClickBtn: () => onClickOpenModal('add'),
          permissionBtn: Permission.battery_create
        }
      } }
      filterMenu={ {
        dropdownsFilter,
        activeFilter,
        onClickMenuItem,
        search,
        placeholderSearch: 'Search by ID',
        onChangeSearch
      } }
      data={ {
        currentData: batteryPacks?.data,
        loading: loadingBatteryPacks,
        fieldName: 'batteryPacks',
        getCurrentPagination
      } }
      table={ {
        columns,
        buttons: buttonsDataTable,
        permissionClickRow: Permission.battery_detail,
        onClickRow,
        onChange: onChangeTable
      } }
      modalForm={ {
        title: 'battery pack',
        visible: modalFormVisible,
        actionModal: actionModalForm,
        onCloseModal: onCloseModalForm,
        footer: {
          fieldName: 'BatteryPack',
          onClickEdit: () => onClickOpenModal('edit'),
          onClickButtonSubmit: onClickButtonModalForm,
        },
        contentModal: renderContentModalForm(),
        permissions: { edit: Permission.battery_change_status }
      } }
      topLoadingBar={ renderTopLoadingBar() }
    />
  );
};

export default BatteryPacks;
