/* eslint-disable prefer-const */
import { Config } from 'consts';
import { Loading, LazyLoad } from 'interfaces/misc';
import { ActionModalForm, Base64Type } from 'typings';

import * as moment from './moment';
import { FormUploadLegal } from 'interfaces/user';

export const getKeyByValue = (obj: any, value: any) => {
  return Object.keys(obj).find(key => obj[key] === value);
};

export const getMultipleKeyByValue = (obj: any, value: any) => {
  return Object.keys(obj).filter(key => obj[key] === value);
};

export const allValuesNotNull = (obj: FormUploadLegal): boolean => {
  return Object.values(obj).every(value => value !== null);
};

export const getMultipleKeyByArrOfValue = (obj: any, values: any[]) => { // array of value
  return Object.keys(obj).filter(key => values.includes(obj[key]));
};

// flatten array
export const flatten = (arr: any) => (arr || []).reduce((flat, next) => flat.concat(next), []);

// flatten object
export const flattenObject = (ob: any) => {
  const toReturn = {};

  for (const i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if ((typeof ob[i]) === 'object' && ob[i] !== null) {
      const flatObject = flattenObject(ob[i]);
      for (const x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + '.' + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }

  return toReturn;
};

export const reverseArray = (arr: any) => {
  return arr.map((_, idx) => arr[arr.length - 1 - idx]);
};

export const capitalizeFirstLetter = (str: string) => {
  return (str || '').charAt(0).toUpperCase() + (str || '').slice(1);
};

export const titleCase = (str: string) => {
  const splitStr = str.toLowerCase().split(' ');

  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }

  return splitStr.join(' ');
};

export const phoneNumberFormat = (number: string, phoneCode = '0') => {
  let newNumber = number;

  if (number.substring(0, 3) === '+62') { // +628xxx
    newNumber = phoneCode + number.substring(3);
  } else if (number.substring(0, 2) === '08') { // 08xxx
    if (phoneCode === '0') {
      newNumber = number;
    } else {
      newNumber = phoneCode + number.substring(1);
    }
  } else if (number.substring(0, 2) === '62') { // 628xxxx
    newNumber = phoneCode + number.substring(2);
  } else if (number.substring(0, 1) === '8') { // 8xxxx
    newNumber = phoneCode + number;
  }

  return newNumber;
};

export const isLoading = (loading: Loading, message: string) => {
  if (loading) {
    return loading.isLoading && loading.loadingMsg === message;
  }

  return false;
};

export const isLazyLoading = (lazyLoad: LazyLoad, keyword: string) => {
  if (lazyLoad) {
    const keyLazy = Object.keys(lazyLoad).find(key => key === keyword);

    return keyLazy ? lazyLoad[keyword] : false;
  }

  return false;
};

export const setModalFormTitle = (actionModalForm: ActionModalForm, field: string) => {
  const fieldTitle = titleCase(field);

  switch (actionModalForm) {
    case 'add':
      return `Add ${ fieldTitle }`;
    case 'edit':
    case 'delete-edit':
      return `Edit ${ fieldTitle }`;
    case 'detail':
      return `${ fieldTitle } Details`;
    default:
      return field;
  }
};

export const removeProperty = (propKey: string, { [propKey]: propValue, ...rest }) => rest; // eslint-disable-line @typescript-eslint/no-unused-vars
export const removeProperties = (object: { [key: string]: any; }, ...keys: string[]) => (keys.length ? removeProperties(removeProperty(keys.pop() || '', object), ...keys) : object);

type NewKey = {
  [key: string]: string;
};

export const renameObjKeys = (obj: { [key: string]: any; }, newKeys: NewKey) => {
  const keyValues = Object.keys(obj).map(key => {
    const newKey = newKeys[key] || key;
    return { [newKey]: obj[key] };
  });

  return Object.assign({}, ...keyValues);
};

export const createDummyData = (length: number, obj?: any) => Array.from(Array(length).keys()).map(() => (Object.assign(obj ? obj : {})));

export const setMultiplierWording = (
  value: number | string,
  wording = 'unit',
  parentheses = false,
) => {
  const multiplier = parentheses ? `${ wording }(s)` : `${ wording }s`;
  const number = + value;

  switch (number) {
    case 0:
      return `${ value }`;
    case 1:
      return `${ value } ${ wording }`;
    default:
      return `${ value } ${ multiplier }`;
  }
};

export const changeSelectFilterValue = (option: string, key: string) => {
  switch (option) {
    case `All ${ capitalizeFirstLetter(key) }`: return '';
    default:
      return option;
  }
};

export const getValueWithoutUnit = (value: string | number, unit: string) => {
  const valueStr = value.toString();
  const end = valueStr.toLowerCase().indexOf(unit.toLowerCase()) !== -1
    ? valueStr.toLowerCase().indexOf(unit.toLowerCase())
    : undefined;

  return valueStr.slice(0, end).trim();
};

export const getUniqueArray = <T>(arr: T[]) => {
  const uniqueArray = arr.filter((thing, index) => {
    const _thing = JSON.stringify(thing);
    return index === arr.findIndex(obj => {
      return JSON.stringify(obj) === _thing;
    });
  });

  return uniqueArray;
};

export const countDuplicateValues = <T>(arr: T[]) => {
  const counter = {};
  arr.forEach(obj => {
    const key = JSON.stringify(obj);
    counter[key] = (counter[key] || 0) + 1;
  });

  return counter;
};

export const countConsecutiveDuplicateValues = <T>(arr: T[]) => {
  const n = arr.length;
  const counter = {};
  let run = 1;

  for (let i = 0; i < n; i++) {
    const value = JSON.stringify(arr[i]);
    const nextValue = JSON.stringify(arr[i + 1]);

    if (i + 1 < n && value === nextValue) {
      run++; // increment run if consecutive elements are equal
    } else {
      counter[value] = run;
      run = 1; // reset run if they are not equal
    }
  }

  return counter;
};

export const timeFormat = (time: number, currentTime: number) => {
  const formatPrevToday = 'D MMM YY, HH:mm';
  const formatToday = 'HH:mm';
  const isToday = moment.timestampToMoment(currentTime).isSame(moment.timestampToMoment(time), 'd');
  const formattedTime = moment.lessThanOneHourAgo(time)
    ? moment.fromNow(time)
    : moment.timestampToDateTime(time, isToday ? formatToday : formatPrevToday);

  return formattedTime;
};

export const currencyFormat = (value: string) => {
  if (value) {
    let number_string = value.replace(/[^.\d]/g, '').toString(),
      split = number_string.split('.'),
      sisa = split[0].length % 3,
      rupiah = split[0].substring(0, sisa),
      ribuan = split[0].substring(sisa).match(/\d{3}/gi);

    if (ribuan) {
      const separator = sisa ? ',' : '';
      rupiah += separator + ribuan.join(',');
    }
    rupiah = split[1] !== undefined ? rupiah + '.' + split[1] : rupiah;

    if (value === '0' && rupiah === '0') return 'IDR 1';
    if (rupiah) {
      return 'IDR ' + rupiah;
    } else {
      return '';
    }
  }

  return '';
};

export const currencyToNumber = (value: string) => {
  if (value) {
    return + (value.split(',').join('')
      .replace('IDR ', ''));
  }

  return 0;
};

export const filterData = <T>(
  inputValue: string,
  data: T[],
  filterBy = 'label'
) => {
  return data?.filter(i => (i[filterBy] || '')?.toLowerCase()
    .replace(/\s+/g, '')
    .includes(inputValue.toLowerCase()?.replace(/\s+/g, '')));
};

export const getBase64 = (file: any): Promise<Base64Type> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

export const getImageUrl = (src: any, external?: boolean) => {
  if (Config.baseImageUrl && external && src) {
    if (src?.includes(Config.baseImageUrl)) return src;

    return `${ Config.baseImageUrl }${ src }`;
  }

  return src;
};

export const getRandColor = () => {
  return '#' + Math.floor(Math.random() * 16777215).toString(16)
    .padStart(6, '0');
};

export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));