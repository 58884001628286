import React, {
  useState,
  useCallback,
  useEffect
} from 'react';

import {
  CartesianGrid,
  Label,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

import {
  Box,
  Text,
  Icon,
  Modal,
  EmptyState,
  Skeleton,
  Pagination,
  Loader
} from 'components';
import { Colors, Images } from 'consts';
import {
  hooks,
  misc,
  moment,
  screen
} from 'helpers';
import {
  DataDetailTripHistory,
  DataTripHistory,
  DataTripHistoryState
} from 'interfaces/scooter';
import { selectors } from 'store/selectors';
import * as actions from 'store/actions';
import { getReverseGeocoding } from 'components/MapsLeaflet/OSMProvider';
import { CurrentPagination, ReducerList } from 'interfaces/common';

import { renderTitleSubContent, renderSubTitleSubContent } from '../../index';

type BoxTripHistoryProps = {
  widthBox: number | string;
  routeId?: string;
};

const BoxTripHistory: React.FC<BoxTripHistoryProps> = ({ widthBox, routeId }) => {
  const dispatch = hooks.useAppDispatch();
  const windowDimensions: hooks.Dimensions = hooks.useWindowDimensions();

  const lazyLoad = hooks.useAppSelector(selectors.misc.lazyLoad);

  const getTripHistory = dispatch(actions.getTripHistory);

  const [modalGraphicVisible, setModalGraphicVisible] = useState<boolean>(false);
  const [modalAllTripVisible, setModalAllTripVisible] = useState<boolean>(false);
  const [selectedTitleModal, setSelectedTitleModal] = useState<string>('');
  const [selectedSubTitleModal, setSelectedSubTitleModal] = useState<string>('');
  const [dataLocation, setDataLocation] = useState<DataDetailTripHistory[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [tripHistory, setTripHistory] = useState<ReducerList<DataTripHistoryState[]>>({
    data: [],
    pagination: {
      count: 0,
      limit: 0,
      offset: 0,
      order: '',
      page: 0,
      page_total: 0,
      search: '',
      sort: 'desc'
    }
  });
  const [loadingGeocoding, setLoadingGeocoding] = useState<boolean>(false);

  const loadingTripHistory = misc.isLazyLoading(lazyLoad, 'TripHistory') || loadingGeocoding;

  const getGeoLocation = (loc: DataDetailTripHistory) => {
    return getReverseGeocoding({
      lat: Number(loc?.latitude),
      lng: Number(loc?.longitude)
    });
  };

  const getTripHistoryAddress = async(updatedTripHistory: ReducerList<DataTripHistory[]>) => {
    setLoadingGeocoding(true);

    const convertedTripHistory = (updatedTripHistory?.data ?? [])?.map((tripHistory: DataTripHistory) => ({
      ...tripHistory,
      start_time: moment.utcToLocal(tripHistory.start_time, 'llll'),
      end_time: moment.utcToLocal(tripHistory.start_time, 'llll')
    }));

    const responseArray = await Promise.all(convertedTripHistory?.map(async(tripHistory: DataTripHistory, tripIdx: number) => {
      await misc.sleep(tripIdx * 1000);

      if (tripHistory?.location?.length) {
        const latestLoc = tripHistory?.location[tripHistory?.location?.length - 1];
  
        if (latestLoc?.latitude && latestLoc?.longitude) {
          return {
            ...tripHistory,
            address: await getGeoLocation(latestLoc).then(res => [res?.village, res?.city].join(', '))
          };
        }
      }

      return tripHistory;
    })).finally(() => setLoadingGeocoding(false));

    setTripHistory(prevTripHistory => ({
      ...prevTripHistory,
      data: [...prevTripHistory.data, ...responseArray],
      pagination: updatedTripHistory?.pagination
    }));
  };

  useEffect(() => {
    if (routeId) {
      getTripHistory({
        vin: + routeId,
        page: currentPage,
        limit: 4,
        sort: 'desc',
        order: 'created_date'
      }, getTripHistoryAddress);
    }
  }, [currentPage]);

  const renderEmptyData = () => {
    return (
      <div className='mt4'>
        <EmptyState
          background={ { height: 275 } }
          image={ {
            src: Images.emptyState.mileage,
            width: 45,
            height: 45
          } }
          text='No Trip History Exist'
        />
      </div>
    );
  };

  const openModalGraphic = (data: DataTripHistoryState) => {
    setDataLocation(data.location);
    setSelectedTitleModal((data.address ?? 'Unknown Street Address') + ' · ' + data.total_energy.toFixed(3) + 'kWh');
    setSelectedSubTitleModal(data.start_time);
    setModalGraphicVisible(true);
  };

  const renderTripHistory = (allData: boolean) => {
    const loadingSkeleton = loadingTripHistory && currentPage === 1;
    const dataTrips = loadingSkeleton
      ? misc.createDummyData(4, {
        id: 0,
        vin: 0,
        trip_id: 0,
        start_time: '',
        end_time: '',
        time_duration: 0,
        total_energy: 0,
        total_distance: 0,
        data_length: 0,
        is_favorite: false,
        location: [],
        created_date: '',
        address: ''
      })
      : allData || tripHistory?.data?.length <= 4
        ? tripHistory?.data
        : tripHistory?.data?.slice(0, 4);

    if (!dataTrips?.length && !loadingSkeleton) return renderEmptyData();

    return dataTrips?.map((data, index) => {
      return (
        <div key={ index } className={ `justify-align-center ${ ((index === 0 && allData) || loadingSkeleton) ? 'mt3' : 'mt5' }` }>
          <div onClick={ () => openModalGraphic(data) } className='flex pointer row align-center'>
            <Skeleton
              key={ index }
              loading={ loadingSkeleton }
              avatar={ {
                size: 36,
                shape: 'circle'
              } }
              title={ false }
            >
              <Icon
                size={ 17 }
                iconName='location'
                fill={ Colors.black.isBlack }
                container='circle'
                containerColor={ Colors.grey.isLighterGrey }
                containerSize={ 36 }
              />
            </Skeleton>
            <Skeleton
              loading={ loadingSkeleton }
              paragraph={ {
                rows: 1,
                width: 100
              } }
              title={ false }
            >
              <div className='ml3'>
                <Text lineHeight={ 17 } mb={ 5 }>
                  { data?.destination_address || 'Unknown Street Address' } &middot; { data.total_energy.toFixed(2) }kWh
                </Text>
                <Text size='xs' color={ Colors.grey.isGrey }>{ data.start_time }</Text>
              </div>
            </Skeleton>
          </div>
        </div>
      );
    });
  };

  const onCloseModal = useCallback(() => setModalGraphicVisible(false), []);

  const renderGrafik = () => {

    const secondRange = 10;

    const dataSecond = dataLocation?.map((u, i) => Object.assign(
      {}, u, {
        second: secondRange * (i + 1),
        power: Number(u.power?.toFixed(3))
      }
    ));
    return (
      <ResponsiveContainer width='100%' height={ 325 }>
        <LineChart
          width={ 650 }
          height={ 325 }
          data={ dataSecond }
          margin={ {
            top: 20,
            right: 10,
            left: 20,
            bottom: 20,
          } }
        >
          <Line
            yAxisId='left'
            type='monotone'
            dataKey={ 'speed' }
            stroke='#6FCF97'
            strokeWidth={ 3 }
            dot={ false }
          />
          <Line
            yAxisId='right'
            type='monotone'
            dataKey={ 'power' }
            stroke='#39AAB4'
            strokeWidth={ 3 }
            dot={ false }
          />

          <XAxis
            allowDataOverflow
            dataKey='second'
            axisLine={ false }>
            <Label
              value='Time (Second)'
              position='insideBottom'
              fontSize={ 14 }
              fill='#000000'
              fontWeight='bold'
              offset={ -20 }
            />
          </XAxis>

          <YAxis
            allowDataOverflow
            yAxisId='left'
            tick={ { fontSize: 10 } }
            axisLine={ false }
            stroke='#6FCF97'>
            <Label
              value='Speed (Km/h)'
              angle={ -90 }
              position='insideLeft'
              fill='#6FCF97'
              fontSize={ 14 }
              fontWeight='bold'
              style={ { marginTop: 50 } }
            />
          </YAxis>
          <YAxis
            allowDataOverflow
            yAxisId='right'
            orientation='right'
            tick={ { fontSize: 10, } }
            axisLine={ false }
            stroke='#39AAB4'>
            <Label
              value='Power (kW)'
              angle={ -90 }
              position='inside'
              fill='#39AAB4'
              fontSize={ 14 }
              fontWeight='bold'
            />
          </YAxis>
          <CartesianGrid
            horizontal={ true }
            vertical={ false } />

          <Tooltip />
        </LineChart>
      </ResponsiveContainer>
    );
  };

  const getCurrentPaginationTripHistory = (currentPagination: CurrentPagination<DataTripHistory[]>) => {
    if (currentPagination.currentPage <= currentPagination.pagesCount) {
      setCurrentPage(currentPagination?.currentPage);
    }
  };

  const renderPaginationTrip = () => {
    if (loadingTripHistory) {
      return <Loader className='col center-content' style={ { marginTop: 25 } } />;
    }

    if (currentPage < tripHistory?.pagination?.page_total) {
      return (
        <div className='mt5'>
          <Pagination
            type='lazyLoad'
            fieldName='scooterTripHistory'
            data={ tripHistory?.data }
            getCurrentPagination={ getCurrentPaginationTripHistory }
          />
        </div>
      );
    }
  };

  const renderModalGraphic = () => {
    return (
      <Modal
        visible={ modalGraphicVisible }
        onCloseModal={ onCloseModal }
        modalType='fixed-scroll'
        title={ () => renderTitleSubContent(selectedTitleModal) }
        subtitle={ () => renderSubTitleSubContent(selectedSubTitleModal) }
        width={ 700 }
        bodyStyle={ { zIndex: 999999 } }
      >
        { renderGrafik() }
      </Modal>
    );
  };

  const renderModalSeeAll = () => {
    const isMobile = windowDimensions.width <= screen.isMobile;
    const widthModal = isMobile ? windowDimensions.width - 30 : 600;

    return (
      <Modal
        visible={ modalAllTripVisible }
        onCloseModal={ () => setModalAllTripVisible(false) }
        width={ widthModal }
        title={ () => renderTitleSubContent('All Trip History') }
      >
        { renderTripHistory(true) }
        { renderPaginationTrip() }
      </Modal>
    );
  };

  const renderContent = () => {

    return (
      <>
        <div className='justify-align-center'>
          { renderTitleSubContent('Trip History') }
          {
            tripHistory?.pagination?.count > 3 && !loadingTripHistory ? (
              <Text
                weight={ 700 }
                color={ Colors.blue.isBlue }
                lineHeight={ 17 }
                align='right'
                onClick={ () => setModalAllTripVisible(true) }
              >See All</Text>
            ) : null
          }
        </div>

        { renderTripHistory(false) }
      </>
    );
  };

  return (
    <>
      <Box
        mt={ 20 }
        padding={ 20 }
        width={ widthBox }
      >
        { renderContent() }
      </Box>
      { renderModalSeeAll() }
      { renderModalGraphic() }
    </>
  );
};

export default BoxTripHistory;
