import axios, { Method, AxiosInstance } from 'axios';
import https from 'https';
import debounce from 'lodash/debounce';

import { Endpoints, Config } from 'consts';
import {
	auth,
	toastify,
	navigation
} from 'helpers';
import {
	ApiData,
	ApiResponseData,
	Pagination
} from 'interfaces/common';
import { ApiPaginationResponse } from 'typings';
import { store } from 'store';
import { LOGOUT } from 'store/Auth/actionTypes';

/* eslint-disable no-console */

const handleLogout = debounce(async() => {
	await auth.removeAuthCredentials();
	store.dispatch({ type: LOGOUT });
	navigation.replace('/login');
	toastify.error('Session expired, please login again');
}, 1000);

export interface Endpoint {
	path: string;
	method: Method;
}

const axiosApiInstance: AxiosInstance = axios.create({
	baseURL: Config.baseUrl,
	timeout: 1000 * 60,
	httpsAgent: new https.Agent({ rejectUnauthorized: false })
});

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(async config => {
	const accessToken = await auth.getAuthCredentials('access_token');
	const refreshToken = await auth.getAuthCredentials('refresh_token');

	// If interceptors calls refreshAccessToken() API, change authorization with refreshToken
	const authorization = config.url === Endpoints.refreshToken().path
		? refreshToken
		: (accessToken || null);

	config.headers = {
		'Authorization': 'Bearer ' + authorization,
		'Accept': 'application/json',
		'Content-Type': 'application/json; charset=utf-8',
		'X-Channel': 'web_cms'
	};

	return config;
}, error => {
	return Promise.reject(error);
});

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(response => {
	return response;
}, async error => {
	const originalRequest = error.config;

	if (!error.response) {
		// network error
		return Promise.reject(error);
	} else {
		const status = error.response.status;
		const data = error.response.data;

		// if (status === 401 && !originalRequest._retry) {
		// 	originalRequest._retry = true;

		// 	try {
		// 		const access_token = await authServices.refreshAccessToken();

		// 		axios.defaults.headers.common['Authorization'] = access_token;
		// 	} catch (error) {
		// 		toastify.errorDefaultApi();
		// 	}

		// 	return axiosApiInstance(originalRequest);
		// }

		if (
			(status === 401
				|| data?.stat_code === 'ERR:AUTHENTICATION'
				|| (status === 400 && data.stat_msg.toLowerCase() === 'token not valid')
				|| (status === 401 && data.stat_msg.toLowerCase() === 'token is invalid')
				|| (status === 401 && data.stat_code === 'ERR:AUTHORIZED')
			)
			&& !originalRequest._retry
		) {
			originalRequest._retry = true;
			handleLogout();

			return Promise.reject(error);
		} else {
			if (data?.stat_code && data?.stat_code === 'ERR:VALIDATION') {
				if (typeof data?.data === 'object') {
					const errMessages = Object.values(data?.data)?.join(', ');

					toastify.error(errMessages);
				}
			} else {
				if (data?.stat_msg) toastify.error(data?.stat_msg);
			}
		}

		return Promise.reject(error);
	}
});

const customFetch = async(endpoint: Endpoint, body?: any) => {
	const url = endpoint.path;
	const method = endpoint.method;

	try {
		const response = await axiosApiInstance.request({
			url,
			method,
			data: (method !== 'GET' && body) || null,
			params: (method === 'GET' && body)
		});

		const result: ApiResponseData<any> = await response.data;

		const data: ApiData<any> = await {
			code: response.status,
			stat_code: result.stat_code,
			stat_msg: result.stat_msg,
			data: result.data,
			pagination: convertPaginationResponse(result.pagination)
		};

		return data;
	} catch (error) {
		// Axios has a property isAxiosError that is used to detect types
		if (axios.isAxiosError(error)) {
			// Access to config, request, and response
			if (error.response) {
				// Request made and server responded
				const result: ApiResponseData<any> = await error.response.data;

				// eslint-disable-next-line no-undef
				console.log('[Error API Request]', result);

				const dataError: ApiData<any> = {
					code: error.response.status,
					stat_code: result.stat_code,
					stat_msg: result.stat_msg,
					data: result.data,
					pagination: convertPaginationResponse(result.pagination)
				};

				return dataError;
			} else {
				/**
				 * The request was made but no response was received
				 * OR
				 * Something happened in setting up the request that triggered an Error
				 */
				throw error;
			}
		} else {
			// Just a stock error
			throw error;
		}
	}
};

export const convertToFormData = (objForm: Record<string, any>) => {
	const formData = new FormData();

	Object.keys(objForm).forEach((key: string) => {
		formData.append(key, objForm[key]);
	});

	return formData;
};

export const convertDataUrlToFile = async(dataUrl: string, fileName: string): Promise<File> => {
	const res: Response = await fetch(dataUrl);
	const blob: Blob = await res.blob();

	return new File(
		[blob],
		fileName,
		{ type: 'image/png' }
	);
};

const convertPaginationResponse = (pagination: ApiPaginationResponse) => {
	let count, offset, page_total;
	let paginationConverted: Pagination = {
		count: 0,
		limit: 10,
		offset: 0,
		order: '',
		page: 1,
		page_total: 1,
		search: '%%',
		sort: 'asc'
	};

	if (pagination && !Array.isArray(pagination)) {
		if (
			('count' in pagination) &&
			('offset' in pagination) &&
			('page_total' in pagination)
		) {
			count = pagination.count;
			offset = pagination.offset;
			page_total = pagination.page_total;
		} else if (
			('record_total' in pagination) &&
			('start' in pagination)
		) {
			count = pagination.record_total;
			offset = pagination.start;
			page_total = Math.ceil((pagination.record_total || 0) / pagination.limit) || 1;
		}

		paginationConverted = {
			count,
			offset,
			page_total,
			limit: pagination.limit,
			order: pagination.order,
			page: pagination.page,
			search: pagination.search,
			sort: pagination.sort
		};
	}

	return paginationConverted;
};

export default customFetch;
